import { version } from "../version.js";
import https from "https";
import qs from "querystring";
import { RequestTimeoutError } from "./errors.js";
/**
 * This `_internals` object is needed to support stubbing/spying of
 * certain functions in this file.
 * https://deno.land/manual@v1.28.3/basics/testing/mocking
 *
 * It's also useful to encapsulate functions that are polyfilled.
 */
export const _internals = {
    execute: execute,
    getBaseUrl: getBaseUrl,
};
/** Facilitates stubbing in tests, e.g. localhost as the base url */
function getBaseUrl() {
    return "https://serpapi.com";
}
export function getSource() {
    var _a, _b;
    const moduleSource = `serpapi@${version}`;
    if (typeof Deno == "object") {
        const denoVersion = (_a = Deno.version) === null || _a === void 0 ? void 0 : _a.deno;
        if (denoVersion) {
            return `deno@${denoVersion},${moduleSource}`;
        }
        // @ts-ignore: scope of nodejs
    }
    else if (typeof process == "object") {
        // @ts-ignore: scope of nodejs
        const nodeVersion = (_b = process.versions) === null || _b === void 0 ? void 0 : _b.node;
        if (nodeVersion) {
            return `nodejs@${nodeVersion},${moduleSource}`;
        }
    }
    return `nodejs,${moduleSource}`;
}
export function buildUrl(path, parameters) {
    const clonedParams = Object.assign({}, parameters);
    for (const k in clonedParams) {
        if (clonedParams[k] === undefined) {
            delete clonedParams[k];
        }
    }
    return `${_internals.getBaseUrl()}${path}?${qs.stringify(clonedParams)}`;
}
export function execute(path, parameters, timeout) {
    const url = buildUrl(path, Object.assign(Object.assign({}, parameters), { source: getSource() }));
    return new Promise((resolve, reject) => {
        let timer;
        const req = https.get(url, (resp) => {
            let data = "";
            // A chunk of data has been recieved.
            resp.on("data", (chunk) => {
                data += chunk;
            });
            // The whole response has been received. Print out the result.
            resp.on("end", () => {
                try {
                    if (resp.statusCode == 200) {
                        resolve(data);
                    }
                    else {
                        reject(data);
                    }
                }
                catch (e) {
                    reject(e);
                }
                finally {
                    if (timer)
                        clearTimeout(timer);
                }
            });
        }).on("error", (err) => {
            reject(err);
            if (timer)
                clearTimeout(timer);
        });
        if (timeout > 0) {
            timer = setTimeout(() => {
                reject(new RequestTimeoutError());
                req.destroy();
            }, timeout);
        }
    });
}
