import { InvalidArgumentError } from "./errors.js";
import { _internals } from "./utils.js";
import { validateApiKey, validateTimeout } from "./validators.js";
const ACCOUNT_PATH = "/account";
const LOCATIONS_PATH = "/locations.json";
const SEARCH_PATH = "/search";
const SEARCH_ARCHIVE_PATH = `/searches`;
export function getJson(...args) {
    if (typeof args[0] === "string" && typeof args[1] === "object") {
        const [engine, parameters, callback] = args;
        const newParameters = Object.assign(Object.assign({}, parameters), { engine });
        return _getJson(newParameters, callback);
    }
    else if (typeof args[0] === "object" &&
        typeof args[1] !== "object" &&
        (typeof args[1] === "undefined" || typeof args[1] === "function")) {
        const [parameters, callback] = args;
        return _getJson(parameters, callback);
    }
    else {
        throw new InvalidArgumentError();
    }
}
async function _getJson(parameters, callback) {
    const key = validateApiKey(parameters.api_key, true);
    const timeout = validateTimeout(parameters.timeout);
    const response = await _internals.execute(SEARCH_PATH, Object.assign(Object.assign({}, parameters), { api_key: key, output: "json" }), timeout);
    const json = JSON.parse(response);
    callback === null || callback === void 0 ? void 0 : callback(json);
    return json;
}
export function getHtml(...args) {
    if (typeof args[0] === "string" && typeof args[1] === "object") {
        const [engine, parameters, callback] = args;
        const newParameters = Object.assign(Object.assign({}, parameters), { engine });
        return _getHtml(newParameters, callback);
    }
    else if (typeof args[0] === "object" &&
        typeof args[1] !== "object" &&
        (typeof args[1] === "undefined" || typeof args[1] === "function")) {
        const [parameters, callback] = args;
        return _getHtml(parameters, callback);
    }
    else {
        throw new InvalidArgumentError();
    }
}
async function _getHtml(parameters, callback) {
    const key = validateApiKey(parameters.api_key, true);
    const timeout = validateTimeout(parameters.timeout);
    const html = await _internals.execute(SEARCH_PATH, Object.assign(Object.assign({}, parameters), { api_key: key, output: "html" }), timeout);
    callback === null || callback === void 0 ? void 0 : callback(html);
    return html;
}
/**
 * Get a JSON response given a search ID.
 * - This search ID can be obtained from the `search_metadata.id` key in the response.
 * - Typically used together with the `async` parameter.
 *
 * @param {string} searchId Search ID.
 * @param {object} parameters
 * @param {string=} [parameters.api_key] API key.
 * @param {number=} [parameters.timeout] Timeout in milliseconds.
 * @param {fn=} callback Optional callback.
 * @example
 * const response = await getJson({ engine: "google", api_key: API_KEY, async: true, q: "coffee" });
 * const { id } = response.search_metadata;
 * await delay(1000); // wait for the request to be processed.
 *
 * // async/await
 * const json = await getJsonBySearchId(id, { api_key: API_KEY });
 *
 * // callback
 * getJsonBySearchId(id, { api_key: API_KEY }, console.log);
 */
export async function getJsonBySearchId(searchId, parameters = {}, callback) {
    const key = validateApiKey(parameters.api_key);
    const timeout = validateTimeout(parameters.timeout);
    const response = await _internals.execute(`${SEARCH_ARCHIVE_PATH}/${searchId}`, {
        api_key: key,
        output: "json",
    }, timeout);
    const json = JSON.parse(response);
    callback === null || callback === void 0 ? void 0 : callback(json);
    return json;
}
/**
 * Get a HTML response given a search ID.
 * - This search ID can be obtained from the `search_metadata.id` key in the response.
 * - Typically used together with the `async` parameter.
 *
 * @param {string} searchId Search ID.
 * @param {object} parameters
 * @param {string=} [parameters.api_key] API key.
 * @param {number=} [parameters.timeout] Timeout in milliseconds.
 * @param {fn=} callback Optional callback.
 * @example
 * const response = await getJson({ engine: "google", api_key: API_KEY, async: true, q: "coffee" });
 * const { id } = response.search_metadata;
 * await delay(1000); // wait for the request to be processed.
 *
 * // async/await
 * const html = await getHtmlBySearchId(id, { api_key: API_KEY });
 *
 * // callback
 * getHtmlBySearchId(id, { api_key: API_KEY }, console.log);
 */
export async function getHtmlBySearchId(searchId, parameters = {}, callback) {
    const key = validateApiKey(parameters.api_key);
    const timeout = validateTimeout(parameters.timeout);
    const html = await _internals.execute(`${SEARCH_ARCHIVE_PATH}/${searchId}`, {
        api_key: key,
        output: "html",
    }, timeout);
    callback === null || callback === void 0 ? void 0 : callback(html);
    return html;
}
/**
 * Get account information of an API key.
 *
 * Refer to https://serpapi.com/account-api for response examples.
 *
 * @param {object} parameters
 * @param {string=} [parameters.api_key] API key.
 * @param {number=} [parameters.timeout] Timeout in milliseconds.
 * @param {fn=} callback Optional callback.
 * @example
 * // async/await
 * const info = await getAccount({ api_key: API_KEY });
 *
 * // callback
 * getAccount({ api_key: API_KEY }, console.log);
 */
export async function getAccount(parameters = {}, 
// deno-lint-ignore no-explicit-any
callback) {
    const key = validateApiKey(parameters.api_key);
    const timeout = validateTimeout(parameters.timeout);
    const response = await _internals.execute(ACCOUNT_PATH, {
        api_key: key,
    }, timeout);
    const info = JSON.parse(response);
    callback === null || callback === void 0 ? void 0 : callback(info);
    return info;
}
/**
 * Get supported locations. Does not require an API key.
 *
 * Refer to https://serpapi.com/locations-api for response examples.
 *
 * @param {object} parameters
 * @param {string=} [parameters.q] Query for a location.
 * @param {number=} [parameters.limit] Limit on number of locations returned.
 * @param {number=} [parameters.timeout] Timeout in milliseconds.
 * @param {fn=} callback Optional callback.
 * @example
 * // async/await
 * const locations = await getLocations({ limit: 3 });
 *
 * // callback
 * getLocations({ limit: 3 }, console.log);
 */
export async function getLocations(parameters = {}, 
// deno-lint-ignore no-explicit-any
callback) {
    const timeout = validateTimeout(parameters.timeout);
    const response = await _internals.execute(LOCATIONS_PATH, parameters, timeout);
    const locations = JSON.parse(response);
    callback === null || callback === void 0 ? void 0 : callback(locations);
    return locations;
}
