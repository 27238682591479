import { config } from "./config.js";
import { InvalidTimeoutError, MissingApiKeyError } from "./errors.js";
export function validateApiKey(value, allowNull = false) {
    // `api_key` can be null to support unmetered queries.
    if (allowNull && value === null) {
        return undefined;
    }
    const key = value !== null && value !== void 0 ? value : config.api_key;
    if (!key)
        throw new MissingApiKeyError();
    return key;
}
export function validateTimeout(value) {
    const timeout = value !== null && value !== void 0 ? value : config.timeout;
    if (timeout <= 0)
        throw new InvalidTimeoutError();
    return timeout;
}
